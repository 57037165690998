import { defineStore } from "pinia";

export const useAuth = defineStore("auth", {
  state: () => ({
    token: useCookie("token", {
      sameSite: true,
      domain: useRuntimeConfig().public.cookieDomain,
      maxAge: 60 * 60,
    }),
    profile: null,
  }),

  actions: {
    async initialize() {
      try {
        await this.refresh();

        setInterval(() => this.refresh(), 10 * 60 * 1000);

        const responses = await Promise.all([
          $fetch(useRuntimeConfig().public.apiUrl + "/api/v1/auth/profile", {
            headers: { Authorization: "Bearer " + this.token },
          }),
        ]);

        this.profile = responses[0].data;

        return true;
      } catch (error) {
        return false;
      }
    },

    async refresh() {
      try {
        const response = await $fetch(
          useRuntimeConfig().public.apiUrl + "/api/v1/auth/tokens/refresh",
          {
            method: "POST",
            headers: { Authorization: "Bearer " + this.token },
          },
        );

        this.token = response.data.attributes.value;
      } catch (error) {
        this.logout();
      }
    },

    logout() {
      this.token = null;

      navigateTo(useRuntimeConfig().public.authDomain, { external: true });
    },
  },
});
