import { default as _91id_93BSBkCgQBliMeta } from "/var/www/sites/admin.pos2.halifax.dk/releases/20240923204549/pages/connection-drivers/[id].vue?macro=true";
import { default as indexL1epUEVYCMMeta } from "/var/www/sites/admin.pos2.halifax.dk/releases/20240923204549/pages/connection-drivers/index.vue?macro=true";
import { default as indexJ4c4TuTW10Meta } from "/var/www/sites/admin.pos2.halifax.dk/releases/20240923204549/pages/index.vue?macro=true";
import { default as indexYkt2IiLf6QMeta } from "/var/www/sites/admin.pos2.halifax.dk/releases/20240923204549/pages/merchants/[id]/index.vue?macro=true";
import { default as merchant_45accessesMlextn1V9QMeta } from "/var/www/sites/admin.pos2.halifax.dk/releases/20240923204549/pages/merchants/[id]/merchant-accesses.vue?macro=true";
import { default as storesvtAH52U1XLMeta } from "/var/www/sites/admin.pos2.halifax.dk/releases/20240923204549/pages/merchants/[id]/stores.vue?macro=true";
import { default as terminals6ai743jhpKMeta } from "/var/www/sites/admin.pos2.halifax.dk/releases/20240923204549/pages/merchants/[id]/terminals.vue?macro=true";
import { default as _91id_93qagllbOd7hMeta } from "/var/www/sites/admin.pos2.halifax.dk/releases/20240923204549/pages/merchants/[id].vue?macro=true";
import { default as indexkULvsx9tivMeta } from "/var/www/sites/admin.pos2.halifax.dk/releases/20240923204549/pages/merchants/index.vue?macro=true";
import { default as _91id_93WKDiCKNt50Meta } from "/var/www/sites/admin.pos2.halifax.dk/releases/20240923204549/pages/users/[id].vue?macro=true";
import { default as indexqLAwSnMLEkMeta } from "/var/www/sites/admin.pos2.halifax.dk/releases/20240923204549/pages/users/index.vue?macro=true";
export default [
  {
    name: _91id_93BSBkCgQBliMeta?.name ?? "connection-drivers-id",
    path: _91id_93BSBkCgQBliMeta?.path ?? "/connection-drivers/:id()",
    meta: _91id_93BSBkCgQBliMeta || {},
    alias: _91id_93BSBkCgQBliMeta?.alias || [],
    redirect: _91id_93BSBkCgQBliMeta?.redirect,
    component: () => import("/var/www/sites/admin.pos2.halifax.dk/releases/20240923204549/pages/connection-drivers/[id].vue").then(m => m.default || m)
  },
  {
    name: indexL1epUEVYCMMeta?.name ?? "connection-drivers",
    path: indexL1epUEVYCMMeta?.path ?? "/connection-drivers",
    meta: indexL1epUEVYCMMeta || {},
    alias: indexL1epUEVYCMMeta?.alias || [],
    redirect: indexL1epUEVYCMMeta?.redirect,
    component: () => import("/var/www/sites/admin.pos2.halifax.dk/releases/20240923204549/pages/connection-drivers/index.vue").then(m => m.default || m)
  },
  {
    name: indexJ4c4TuTW10Meta?.name ?? "index",
    path: indexJ4c4TuTW10Meta?.path ?? "/",
    meta: indexJ4c4TuTW10Meta || {},
    alias: indexJ4c4TuTW10Meta?.alias || [],
    redirect: indexJ4c4TuTW10Meta?.redirect,
    component: () => import("/var/www/sites/admin.pos2.halifax.dk/releases/20240923204549/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93qagllbOd7hMeta?.name ?? undefined,
    path: _91id_93qagllbOd7hMeta?.path ?? "/merchants/:id()",
    meta: _91id_93qagllbOd7hMeta || {},
    alias: _91id_93qagllbOd7hMeta?.alias || [],
    redirect: _91id_93qagllbOd7hMeta?.redirect,
    component: () => import("/var/www/sites/admin.pos2.halifax.dk/releases/20240923204549/pages/merchants/[id].vue").then(m => m.default || m),
    children: [
  {
    name: indexYkt2IiLf6QMeta?.name ?? "merchants-id",
    path: indexYkt2IiLf6QMeta?.path ?? "",
    meta: indexYkt2IiLf6QMeta || {},
    alias: indexYkt2IiLf6QMeta?.alias || [],
    redirect: indexYkt2IiLf6QMeta?.redirect,
    component: () => import("/var/www/sites/admin.pos2.halifax.dk/releases/20240923204549/pages/merchants/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: merchant_45accessesMlextn1V9QMeta?.name ?? "merchants-id-merchant-accesses",
    path: merchant_45accessesMlextn1V9QMeta?.path ?? "merchant-accesses",
    meta: merchant_45accessesMlextn1V9QMeta || {},
    alias: merchant_45accessesMlextn1V9QMeta?.alias || [],
    redirect: merchant_45accessesMlextn1V9QMeta?.redirect,
    component: () => import("/var/www/sites/admin.pos2.halifax.dk/releases/20240923204549/pages/merchants/[id]/merchant-accesses.vue").then(m => m.default || m)
  },
  {
    name: storesvtAH52U1XLMeta?.name ?? "merchants-id-stores",
    path: storesvtAH52U1XLMeta?.path ?? "stores",
    meta: storesvtAH52U1XLMeta || {},
    alias: storesvtAH52U1XLMeta?.alias || [],
    redirect: storesvtAH52U1XLMeta?.redirect,
    component: () => import("/var/www/sites/admin.pos2.halifax.dk/releases/20240923204549/pages/merchants/[id]/stores.vue").then(m => m.default || m)
  },
  {
    name: terminals6ai743jhpKMeta?.name ?? "merchants-id-terminals",
    path: terminals6ai743jhpKMeta?.path ?? "terminals",
    meta: terminals6ai743jhpKMeta || {},
    alias: terminals6ai743jhpKMeta?.alias || [],
    redirect: terminals6ai743jhpKMeta?.redirect,
    component: () => import("/var/www/sites/admin.pos2.halifax.dk/releases/20240923204549/pages/merchants/[id]/terminals.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexkULvsx9tivMeta?.name ?? "merchants",
    path: indexkULvsx9tivMeta?.path ?? "/merchants",
    meta: indexkULvsx9tivMeta || {},
    alias: indexkULvsx9tivMeta?.alias || [],
    redirect: indexkULvsx9tivMeta?.redirect,
    component: () => import("/var/www/sites/admin.pos2.halifax.dk/releases/20240923204549/pages/merchants/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93WKDiCKNt50Meta?.name ?? "users-id",
    path: _91id_93WKDiCKNt50Meta?.path ?? "/users/:id()",
    meta: _91id_93WKDiCKNt50Meta || {},
    alias: _91id_93WKDiCKNt50Meta?.alias || [],
    redirect: _91id_93WKDiCKNt50Meta?.redirect,
    component: () => import("/var/www/sites/admin.pos2.halifax.dk/releases/20240923204549/pages/users/[id].vue").then(m => m.default || m)
  },
  {
    name: indexqLAwSnMLEkMeta?.name ?? "users",
    path: indexqLAwSnMLEkMeta?.path ?? "/users",
    meta: indexqLAwSnMLEkMeta || {},
    alias: indexqLAwSnMLEkMeta?.alias || [],
    redirect: indexqLAwSnMLEkMeta?.redirect,
    component: () => import("/var/www/sites/admin.pos2.halifax.dk/releases/20240923204549/pages/users/index.vue").then(m => m.default || m)
  }
]